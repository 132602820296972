import PropTypes from 'prop-types';
import { nullValidator } from './validators';

export const numberOrStringOrArrayPlusNull = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, nullValidator])
  )
]);
