import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../domain/theme';
import { Button } from '../../../responsive/atoms/Button';
import { conf } from '../../../../domain/services/configService';

// @todo - move this to Drupal config
const locales = {
  es: {
    text: 'Estás a punto de abandonar www.nationalgeographic.es. La página web que vas a visitar no es titularidad de The Walt Disney Company Limited. Por ello, se aplicarán los Términos de Uso y la Política de Privacidad del propietario del sitio web.',
    continue: 'CONTINUAR'
  },
  fr: {
    text: 'Vous êtes sur le point de quitter www.nationalgeographic.fr. La page que vous vous apprêtez à consulter n’est pas sous le contrôle de The Walt Disney Company Limited. Les conditions d’utilisation et la politique de confidentialité du propriétaire du site seront appliquées.',
    continue: 'CONTINUER'
  },
  de: {
    text: 'Dieser Link öffnet eine Website außerhalb von nationalgeographic.de. The Walt Disney Company ist für den Inhalt externer Seiten nicht verantwortlich. Es gelten die Nutzungsbedingungen und Privatsphäreneinstellungen der verlinkten Seite.',
    continue: 'WEITER'
  }
};

export const ExternalLinkDisclaimer = ({ closeCb, link }) => {
  let timeoutId;
  const removeTimeout = () => clearTimeout(timeoutId);

  const sendEvent = action =>
    window.dataLayer.push({
      event: 'click_optout',
      action_button: action,
      outclick: link
    });

  const clickHandler = () => {
    removeTimeout();
    sendEvent('Continue');
    closeCb();
  };

  const timeoutReachedHandler = () => {
    removeTimeout();
    sendEvent('AutoContinue');
    closeCb();
  };

  useEffect(() => {
    timeoutId = setTimeout(() => {
      timeoutReachedHandler();
      document.location.href = link;
    }, 5000);
    return () => removeTimeout();
  }, []);

  const styles = {
    container: css`
      padding: ${theme.spacing('base')};
      ${theme.mq.tablet} {
        padding: ${theme.spacing('l')};
      }
      background: url(/images/climber.jpg) no-repeat center right;
      background-size: cover;
      text-align: center;
      color: #fff;
      ${theme.font.family('regularWeb')};
    `,

    header: css`
      font-size: 1.5em;
      margin-top: 0;
    `,

    paragraph: css`
      margin: ${theme.spacing('base')} 0;
      ${theme.mq.tablet} {
        margin: ${theme.spacing('base')};
      }
    `
  };

  return (
    <div css={styles.container} data-id="external-link-disclaimer">
      {/* <h1 css={styles.header}>YOU ARE LEAVING THIS SITE</h1> */}

      <p css={styles.paragraph}>{locales[conf.siteCountryCode]?.text}</p>

      <Button
        to={link}
        onClick={clickHandler}
        target="_blank"
        padding="xs"
        data-no-external-disclaimer
      >
        {locales[conf.siteCountryCode]?.continue}
      </Button>
    </div>
  );
};

ExternalLinkDisclaimer.defaultProps = {
  link: null
};

ExternalLinkDisclaimer.propTypes = {
  closeCb: PropTypes.func.isRequired,
  link: PropTypes.string
};
