import { isNull } from '../../../domain/utils/typeUtils';

export const nullValidator = (props, propName, componentName) => {
  if (isNull(props[propName])) {
    return null;
  }
  return new TypeError(
    `Failed prop type: Invalid prop '${propName}' supplied to ${componentName}, prop must be null; received ${typeof props[
      propName
    ]}`
  );
};

nullValidator.isRequired = nullValidator;
